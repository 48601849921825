/* eslint-disable prefer-destructuring */
import { getToken, getCustomer } from '@modules/login/services/graphql';
import { expiredToken, custDataNameCookie } from '@config';
import { setLogin, removeIsLoginFlagging } from '@helper_auth';
import { useRouter } from 'next/router';
import Layout from '@layout';
import { regexEmail } from '@helper_regex';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import qs from 'querystring';
import { removeLocalStorage } from '@helper_localstorage';

const ContentWrapper = (props) => {
    const {
        Content, storeLogo, t, recaptcha, isAdmin,
    } = props;
    const router = useRouter();
    const [confirmNotif, setConfirmNotif] = React.useState(false);
    const [token, setToken] = React.useState();

    const [getCustomerData] = getCustomer({
        onCompleted: () => {
            Cookies.remove(custDataNameCookie);
            if (isAdmin) {
                router.push('/admin/automation/automationlist');
            } else {
                router.push('/catalog');
            }
        },
        onError: (e) => {
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                text: e.message,
                variant: 'error',
            });
        },
    });

    const [getCustomerToken] = getToken();
    const recaptchaRef = React.createRef();
    const { secretkey } = recaptcha;

    const handleSubmit = (variables) => {
        getCustomerToken({
            variables,
        }).then(async (res) => {
            const tokenRes = res.data.internalGenerateCustomerToken.token;
            if (tokenRes) {
                if (isAdmin) {
                    Cookies.set('is_admin', 1);
                } else {
                    Cookies.set('is_admin', 0);
                }
                setToken(tokenRes);
            }
        }).catch((e) => {
            window.backdropLoader(false);
            if (e.message.search('graphql-customer-unconfirmed') >= 0) {
                setConfirmNotif(true);
            } else {
                setConfirmNotif(false);
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: e.message.split(':')[0],
                });
            }
        });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            captcha: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(t('login:This_is_a_Required_field')).matches(regexEmail, t('login:Invalid_email_format')),
            password: Yup.string().required(t('login:This_is_a_Required_field')),
            captcha: recaptcha.enable && Yup.string().required(`Captcha ${t('registervendor:required')}`),
        }),
        onSubmit: async (values) => {
            const { captcha, ...restValues } = values;
            window.backdropLoader(true);
            if (recaptcha.enable) {
                fetch('/captcha-validation', {
                    method: 'POST',
                    body: qs.stringify({
                        secret: secretkey,
                        response: captcha,
                    }),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.success) {
                            handleSubmit(restValues);
                        } else {
                            window.backdropLoader(false);
                            window.toastMessage({
                                open: true,
                                variant: 'error',
                                text: t('login:There_is_something_error_while_verifying_captcha'),
                            });
                        }
                    })
                    .catch(() => {
                        window.backdropLoader(false);
                        window.toastMessage({
                            open: true,
                            variant: 'error',
                            text: t('login:Could_not_verify_captcha'),
                        });
                    });
                recaptchaRef.current.reset();
            } else {
                handleSubmit(restValues);
            }
        },
    });

    React.useEffect(() => {
        if (router.query?.confirm) {
            if (router.query?.confirm === 'success') {
                window.toastMessage({
                    open: true,
                    text: t('login:Thank_you_for_registering_Please_log_in_to_your_account'),
                    variant: 'success',
                });
            } else if (router.query?.confirm === 'failed' && Cookies.get('error_confirmation')) {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: Cookies.get('error_confirmation'),
                });
            }
        }
    }, []);

    React.useEffect(async () => {
        if (token) {
            removeIsLoginFlagging();
            setLogin(1, expiredToken);
            getCustomerData();
        }
    }, [token]);

    const contentProps = {
        ...props,
        formik,
        storeLogo,
        t,
        recaptchaRef,
        confirmNotif,
    };

    return (
        <Content {...contentProps} />
    );
};

const Core = (props) => {
    const { t, storeConfig } = props;
    const router = useRouter();
    const isAdmin = router?.asPath?.includes('admin');

    const pageConfig = {
        title: t('login:Login'),
        header: false,
        sidebar: false,
    };

    React.useEffect(() => {
        removeLocalStorage('acl');
    }, []);

    const contentProps = {
        ...props,
        recaptcha: {
            enable: storeConfig?.msp_securitysuite_recaptcha_enabled === '1'
                && storeConfig?.msp_securitysuite_recaptcha_enabled_login === '1',
            sitekey: storeConfig?.msp_securitysuite_recaptcha_public_key,
            secretkey: storeConfig?.msp_securitysuite_recaptcha_private_key,
        },
        isAdmin,
    };

    return (
        <Layout pageConfig={pageConfig}>
            <ContentWrapper
                {...contentProps}
            />
        </Layout>
    );
};

export default Core;
